import { Outlet } from "@remix-run/react";
import { BackgroundAnimated } from "~/components";

export default function () {
  return (
    <BackgroundAnimated>
      <Outlet />
    </BackgroundAnimated>
  );
}
